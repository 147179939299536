@font-face {
  font-family: "Inter var";
  src: url("./fonts/Inter.ttf") format("truetype");
  font-weight: 100 900;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#root {
  height: 100%;
}

html, body {
  height: 100%;
}

div {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

p {
  margin: 0;
  padding: 0;
}

.u-flex-direction-row {
  flex-direction: row;
}

.u-flex-none {
  flex: none;
}

.u-align-items-center {
  align-items: center;
}

.u-justify-content-flex-end {
  justify-content: flex-end;
}

.u-display-none {
  display: none;
}

.u-margin-Ls {
  margin-left: 5px;
}

.u-margin-R {
  margin-right: 10px;
}

.u-margin-Rs {
  margin-right: 5px;
}

.u-margin-Rl {
  margin-right: 20px;
}

.u-margin-Rxl {
  margin-right: 30px;
}

.u-margin-B {
  margin-bottom: 15px;
}

.u-link,
.u-link:visited {
  color: #222;
}

.u-link:hover,
.u-link:active {
  text-decoration: none;
}

.u-link-clean,
.u-link-clean:visited,
.u-link-clean:hover,
.u-link-clean:active {
  text-decoration: none;
  color: #222;
}

.u-inline-code {
  font-family: 'Menlo', 'Monaco', monospace;
}

.u-text-xxs {
  font-size: 11px;
}

.u-relative {
  position: relative;
}
