.fret {
  text-align: center;
  color: #ccc;
  position: relative;
  padding: 10px 5px;
  align-items: center;
  justify-content: center;
  flex-basis: 0;
}

.fret__dot {
  width: 5px;
  height: 5px;
  background: #333;
  border-radius: 50%;
  margin: 1px;
  border: 1px solid #333
}

.fret__dot--open {
  background: white;
}

@media (min-width: 500px) {
  .fret {
    padding: 5px 0;
  }
}

.fret:first-child {
  border-bottom: 2px solid lightgray;
}

.fret--marker:first-child {
  border-color: transparent;
}

@media (min-width: 500px) {
  .fret:first-child {
    border-bottom: 0;
    border-right: 2px solid lightgray;
  }

  .strings--left-handed .fret:first-child {
    border-right: 0;
    border-left: 2px solid lightgray;
  }

  .fret--marker:first-child {
    border-color: transparent;
  }
}

.fret:before {
  content: "";
  width: 1px;
  height: 100%;
  left: 50%;
  position: absolute;
  background: lightgray;
  z-index: 0;
}

.fret--marker:before {
  background: transparent;
}

@media (min-width: 500px) {
  .fret:before {
    top: 50%;
    left: 0;
    margin-top: -1px;
    height: 1px;
    width: 100%;
  }
}

.fret__label {
  box-sizing: border-box;
  color: transparent;
  z-index: 1;
  position: relative;
  width: 29px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fret__label--empty {
  /* height: 3px;
  width: 7px;
  background: #D6D6D6;
  top: -0.5px;
  border-radius: 1000px; */
}

.fret__label--visible,
.fret__label--highlighted,
.fret__label--triad {
  /* border: 1px solid lightgray; */
  font-size: 13px;

  border-radius: 50%;
  background: white;
  font-weight: 450;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);

}

.fret__label__inner {
  width: 25px;
  height: 25px;
  flex: none;
  line-height: 25px;
  border-radius: 1000px;
}

.fret__label__inner--empty {
  height: 7px;
  width: 3px;
  line-height: unset;
  background: #D6D6D6;
  position: relative;
  left: 0.5px;
  border-radius: 1000px;
}

.fret__label--visible {
  background: linear-gradient(180deg, #B6B6B6 0%, #999999 100%);
}

.fret__label__inner--visible {
  color: #444;
  background: white;
}

.fret__label--highlighted {
  background: linear-gradient(180deg, #6D6D6D 0%, #333333 100%);
}

.fret__label__inner--highlighted {
  background: transparent;
  color: white;
}

.fret__label--triad {
  background: linear-gradient(180deg, #B6B6B6 0%, #999999 100%);
  color: #444;
}

.fret__label__inner--triad {
  background: #E1E1E1;
}

.fret__label__small-text {
  font-size: 11px;
  line-height: 25px;
}

.fret__divider {
  position: absolute;
  width: 100%;
  height: 1px;
  background: lightgray;
  left: 50%;
  bottom: 0;
}

@media (min-width: 500px) {
  .fret__divider {
    width: 1px;
    height: 100%;
    right: 0;
    left: auto;
    bottom: auto;
    top: 50%;
  }

  .fret__label__inner--empty {
    height: 3px;
    width: 7px;
    top: -0.5px;
    left: unset;
  }
}

.string:last-child .fret__divider {
  display: none;
}

.strings--left-handed .string:first-child .fret__divider {
  display: none;
}

.strings--left-handed .string:last-child .fret__divider {
  display: flex;
}

.strings--left-handed .string:last-child .fret:first-child .fret__divider {
  display: none;
}

.strings--left-handed .string:last-child .fret:last-child .fret__divider {
  display: none;
}

@media (min-width: 500px) {
  .string:last-child .fret__divider {
    display: flex;
  }

  .string:first-child .fret__divider {
    display: flex;
    top: 0;
    width: 1px;
    height: 50%;
  }

  .strings--left-handed .fret:last-child .fret__divider,
  .strings--left-handed .string:last-child .fret:last-child .fret__divider {
    width: 1px;
    height: 100%;
    right: 0;
    left: auto;
    bottom: auto;
    top: 50%;
    display: flex;
  }

  .strings--left-handed .string:last-child .fret__divider {
    /*display: none;*/
  }

  .strings--left-handed .fret:nth-child(2) .fret__divider {
    display: none;
  }

  .strings--left-handed .string:first-child .fret:last-child .fret__divider {
    display: none;
  }

  .app__fretboard-markers--left-handed .fret:first-child {
    border-right: 0;
    border-left: 2px solid transparent;
  }
}

.fret:first-child .fret__divider {
  display: none;
}

.fret:last-child .fret__divider {
  display: none;
}
