@keyframes modalFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes modalScaleUp {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.app {
  font-family: "Inter var", -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
  height: 100%;
  min-width: 324px;
  font-weight: 350;
  font-feature-settings: 'ss03' 1, 'cv01' 1, 'cv02' 1, 'cv03' 1, 'cv04' 1, 'cv05' 1, 'cv09' 1, 'cv10' 1;
}

@media (min-width: 750px) {
  .app {
    flex-direction: row;
    align-items: center;
  }
}

.app__fretboard {
  min-width: 100%;
  /*overflow: hidden;*/
  overflow-y: auto;
  align-items: center;
  position: relative;
  -webkit-overflow-scrolling: touch;
}

.app__fretboard-strings {
  min-height: 500px;
  flex: 1 0 auto;
  flex-direction: row;
  position: relative;
}

.app__fretboard-markers-container {
  position: absolute;
  right: 100%;
  margin-right: 10px;
  height: 100%;
}

.app__bottom-nav {
  flex: 0 0 auto;
  padding: 10px;
}

.app__hand-toggle--nav,
.app__tuning-select--nav {
  display: none;
}

.app__container {
  height: 100%;
}

@media (min-width: 500px) {
  .app__container {
    height: auto;
  }

  .app__fretboard {
    min-height: auto;
    align-items: stretch;
  }

  .app__fretboard-markers-container {
    position: relative;
    right: auto;
    height: auto;
    margin-right: 0;
    margin-top: 10px;
  }

  .app__fretboard-strings {
    flex-direction: column;
    min-height: auto;
  }
}

@media (min-width: 750px) {
  .app__bottom-nav {
    display: none;
  }

  .app__hand-toggle--nav,
  .app__tuning-select--nav {
    display: flex;
  }

  .app__hand-toggle--bottom-nav,
  .app__tuning-select--bottom-nav {
    display: none;
  }
}

.app__nav {
  padding: 10px;
  flex: 0 0 auto;
}

@media (min-width: 750px) {
  .app__nav {
    padding: 0 20px 20px 20px;
  }
}

.app__help-icon {
  position: fixed;
  display: inline-block;
  bottom: 50%;
  margin-top: -9px;
  right: 10px;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: 1px solid #ccc;
  line-height: 18px;
  text-align: center;
  font-size: 11px;
  color: #ccc;
}

.app__help-icon:hover {
  color: #222;
  border-color: #222;
  cursor: pointer;
}

.app__fretboard-markers {
  flex: 1 1 auto;
}

@media (min-width: 500px) {
  .app__fretboard-markers {
    flex-direction: row;
    flex: none;
  }

  .app__fretboard-markers--left-handed {
    flex-direction: row-reverse;
  }

  .app__help-icon {
    bottom: 10px;
    left: 50%;
    margin-left: -9px;
  }
}

@media (min-width: 750px) {
  .app__help-icon {
    bottom: 10px;
    margin-top: 0;
    left: auto;
    margin-left: 0;
  }
}

.strings {
  flex-direction: row;
}

.strings--left-handed {
  flex-direction: row-reverse;
}

@media (min-width: 500px) {
  .strings {
    flex-direction: column-reverse;
  }

  .strings--left-handed {
    /*flex-direction: column;*/
    /*justify-content: flex-end;*/
  }
}
