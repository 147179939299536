.help-modal__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 2;
  align-items: center;
  justify-content: center;
  opacity: 0;
  animation: modalFadeIn 0.2s cubic-bezier(0.55, 0, 0.1, 1) forwards;
}

.help-modal__content {
  background: white;
  flex: none;
  text-align: center;
  padding: 30px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
  transform: scale(0.9);
  animation: modalScaleUp 0.2s cubic-bezier(0.55, 0, 0.1, 1) forwards;
  position: relative;
}

.help-modal__close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0.3;
}

.help-modal__close-button:hover {
  cursor: pointer;
  opacity: 1;
}
