.notes-intervals-toggle__label {
  color: #ccc;
}

.notes-intervals-toggle__label:hover {
  cursor: pointer;
}

.notes-intervals-toggle__label.is-active {
  color: #333;
}
