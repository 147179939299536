.hand-toggle__label {
  color: #ccc;
  width: 37px;
}

.hand-toggle__label:hover {
  cursor: pointer;
}

.hand-toggle__label.is-active {
  color: #333;
}

.hand-toggle__icon {
  position: absolute;
  top: -1px;
}

.hand-toggle__icon--left {
  right: 0;
}

.hand-toggle__icon--right {
  left: 0;
}
