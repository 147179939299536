.string:nth-last-child(1)::after {
  display: block;
  content: '';
  position: absolute;
  width: 30px;
  height: 100%;
  right: -11.5px;
  overflow: hidden;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.05) 0%, #fff 50%);
}

.string:nth-child(1)::after {
  display: block;
  content: '';
  position: absolute;
  width: 30px;
  left: -10.5px;
  height: 100%;
  overflow: hidden;
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.05) 0%, #fff 50%);
}

@media (min-width: 500px) {
  .string {
    flex-direction: row;
    position: relative;
  }

  .strings--left-handed .string {
    flex-direction: row-reverse;
  }

  .string:nth-last-child(1)::after {
    display: none;
  }

  .string:nth-child(1)::after {
    top: 50%;
    left: unset;
    width: 100%;
    height: 50%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.05) 0%, #fff 50%);
  }
}

